<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      
      
    </CSidebarBrand>

    <!-- <CRenderFunction flat :content-to-render="$options.nav"/> -->
     <CRenderFunction flat :content-to-render="sidebarItems()"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
    data() {
    return {};
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },
  methods: {
    currentItems() {
     
      if(this.$store.state.roles != 'ROLE_SUPER_ADMIN'){
       console.log("groupNames", this.$store.state.groupNames);
       console.log("groupNames", this.$store.state.authority);
      let groupNames = this.$store.state.groupNames;

      let nNav = nav
        .map((item) => {
          if (item._name === "CSidebarNavDropdown") {
            let sItems = item.items
              .map((it) => {
                if (!it.groupNames) return it;
                if (it.groupNames.some((r) => groupNames.includes(r))) return it;
                return -1;
              })
              .filter((it) => it != -1);
             
            console.log("sItems", sItems);
            let nItem =
              sItems.length > 0 ? Object.assign(item, { items: sItems }) : -1;
            console.log("nItem", nItem);
            return nItem;
          }

          if (!item.groupNames) return item;
          if (item.groupNames.some((r) => groupNames.includes(r))) return item;
          return -1;
        })
        .filter((it) => it != -1);
        console.log("nNav", nNav);
      return nNav;
      }
    else{
    
    let nNav = nav;
    console.log("nNav", nNav);
      return nNav;
    }
     // console.log("nNav", nNav);
     
    },
    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.currentItems(),
        },
      ];
    },
  },
}
</script>
<style>
.c-sidebar {
    color: rgb(17, 19, 17);
    background:#eeb657;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #1fdb7d;
}

.c-sidebar a:hover:not(.active) {
  background-color: #4884bd;
   color: rgb(34, 31, 31); 
}

.router-link-exact-active.c-active.c-sidebar-nav-link{
    background-color:  #4884bd;
  
  }

  .page-item.active .page-link{
    z-index: 3;
    color: #fff;
    background-color: #39f;
    border-color: #39f;
}
  .c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: rgba(255, 255, 255, 0.8);
    background: transparent;
     font-weight: bold;
}
</style>

