<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="img/9.jpg"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Settings</strong>
    </CDropdownHeader>
   
    <!-- <CDropdownItem>
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem> -->
   
    <CDropdownDivider/>
    <CDropdownItem @click="logoutUser()">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42
    }
  },
  methods:{
    logoutUser(){
      console.log("onlogout","call logout");
       this.$store.commit("addUser", undefined);
       this.$router.push({name:"Login"}).then(()=>{
         console.log("signed out");
           });
    },
    },
  }



</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>