export default [
  // {
  //   _name: 'CSidebarNav',
  //   _children: [
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Dashboard',
      //   to: '/dashboard',
      //   icon: 'cil-speedometer',
      //   badge: {
      //     color: 'primary',
      //     text: 'NEW'
      //   },
     
      //  //roles: ["ROLE_ADMIN","ROLE_SUPER_ADMIN"],
      //  //groupNames: ["Equipment", "and", "maintenance","Civil","Maintenance"],
      // },

      {
        _name: 'CSidebarNavItem',
        name: 'Groups',
        to: '/groups',
        icon: 'groups',
        //roles: ["ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance","Civil","Maintenance"],
      },
    
      {
        _name: 'CSidebarNavDropdown',
        name: 'Users',
       // to: '/users',
        icon: 'users',
        //roles: ["ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance","Civil","Maintenance"],
        items: [
         
          {
           
            name: 'App Users',
            to: '/users',
            icon: 'cilUser',
           // roles: ["ROLE_SUPER_ADMIN"]
          },
          {
            name: 'Admin Users',
            to: '/cmsUsers',
            badge: {
             // color: 'primary',
              //  dynamic_method: 'publishedCount',
              //  id: 'publishedCount'
            },
            icon: 'cil-user-follow',
          //  roles: ["ROLE_SUPER_ADMIN"]
          },
         
          
        ]
      },
    
      
      {
        _name: 'CSidebarNavItem',
        name: 'Briefings',
        to: '/briefings',
        icon: 'cilList',
        roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance","Civil","Maintenance"],
      },

     
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Work Permits',
      //   to: '/workpermits',
      //   icon: 'cilGlobeAlt'
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Jobs',
        to: '/jobs',
        icon: 'briefings',
        //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance"]
          
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Cranes',
        to: '/cranes',
        icon: 'crane',
        //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance"],      
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Civil Jobs',
        to: '/civilMaintenances',
        icon: 'cilTask', 
        //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Civil","Maintenance"],   
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Work Permit Requests',
        to: '/workpermitRequests',
        icon: 'workPermitRequests',
        //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance","Civil","Maintenance"],  
      },
      {
        _name: 'CSidebarNavItem',
        name: 'QMS Templates',
        to: '/qmsTemplates',
        icon: 'qmstemplate' ,
        //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance"],  
      },
     
      {
        _name: 'CSidebarNavItem',
        name: 'Tyre Change Forms',
        to: '/tyreChangeForms',
        icon: 'cilTask' ,
        //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance"],  
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Settings',
        icon: 'cilSettings',
        //roles: ["ROLE_ADMIN","ROLE_SUPER_ADMIN"],
        //groupNames: ["Equipment", "and", "maintenance","Civil","Maintenance"],
        items: [
          {
            _name: 'CSidebarNavItem',
            name: 'MED Meta Data',
            to: '/medMatadata',
            icon: 'cilGrid' ,
            roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
          //  groupNames: ["Equipment", "and", "maintenance"],  
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Approval Permit Skill Set',
            to: '/approvalPermitSkillSets',
            icon: 'cilPuzzle' ,
            roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
          //  groupNames: ["Equipment", "and", "maintenance"],  
          },
          // {
          //   _name: 'CSidebarNavItem',
          //   name: 'Tyre Form Department',
          //   to: '/tyreDepartments',
          //   icon: 'cilPuzzle' ,
          //   roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
          //   groupNames: ["Equipment", "and", "maintenance"],  
          // },
          {
            _name: 'CSidebarNavItem',
            name: 'OT Hour Limits',
            to: '/settingValue',
            icon: 'cilSettings', 
            //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
            //groupNames: ["Civil","Maintenance"],   
          },
          
        ]
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Setting Value',
      //   to: '/settingValue',
      //   icon: 'cilSettings', 
      //   //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
      //   //groupNames: ["Civil","Maintenance"],   
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Upper Lower Value',
      //   to: '/upperLowerList',
      //   icon: 'cilTask', 
      //   //roles: [ "ROLE_ADMIN","ROLE_SUPER_ADMIN"],
      //   //groupNames: ["Civil","Maintenance"],   
      // },
    
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Tasks',
      //   to: '/tasks',
      //   icon: 'cil-task'
     
          
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Items',
      //   to: '/items',
      //   icon: 'cil-options'
     
          
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Users',
      //   to: '/MyUsers',
      //   icon: 'cil-task'
     
          
      // }
    //]
  //}
]